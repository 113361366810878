import React, { useState } from 'react';
import '../assets/styles/Footer.css';
import { BsSubstack } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';

const socialLinks = [
  {
    href: 'https://www.x.com',
    icon: <FaXTwitter />,
    label: 'Twitter',
  },
  {
    href: 'https://substack.com/',
    icon: <BsSubstack />,
    label: 'Substack',
  },
];

const Footer = () => {
  const location = useLocation();  // Get the current location
  const isHomePage = location.pathname === '/';
  const [email, setEmail] = useState('');  // State to store the email input
  const [message, setMessage] = useState('');  // State to store the success/error message

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');  // Reset the message

    // The URL of your Google Apps Script web app (replace with your URL)
    const googleAppsScriptURL = 'https://script.google.com/macros/s/AKfycbzLVwWKRWKDXBjBec2YQSFm-aWiB9cLItbmFBvk8laYOwFCb8ypWCdc3F5kNH1Ixrty/exec';

    // Prepare the data
    const formData = new URLSearchParams();
    formData.append('email', email);

    try {
      // Send the POST request
      await fetch(googleAppsScriptURL, {
        method: 'POST',
        mode: 'no-cors',  // no-cors allows for cross-origin POST
        body: formData,
      });

      // Since we can't check the response due to no-cors, assume success if no error is thrown
      setMessage('Welcome aboard! 🚀 Stay tuned for exciting updates!');
    } catch (error) {
      console.error("Error:", error);
      setMessage('Something went wrong. Please try again.');
    }

    // Clear the email input
    setEmail('');
  };

  return (
    <footer className="footer">
      <div className="footer-input-section">
        <h2>Be the first to know</h2>
        <form onSubmit={handleSubmit} className="footer-input">
          <div className="input-wrapper">
            <i className="fas fa-envelope" aria-hidden="true"></i>
            <input
              type="email"
              placeholder="Your email address"
              aria-label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}  // Update the state with the email input
              required
            />
          </div>
          <button type="submit">Get early access</button>
        </form>
        {message && <p>{message}</p>}  {/* Show the message */}
      </div>
      <div className="footer-content">
        <div className="footer-left">
          {socialLinks.map((link, index) => (
            <a key={index} href={link.href} target="_blank" rel="noopener noreferrer" aria-label={link.label}>
              {link.icon}
            </a>
          ))}
        </div>
        <div className="footer-center">
          {isHomePage ? (
            <Link to="/about-us" aria-label="About Us">About Us</Link>
          ) : (
            <Link to="/" aria-label="Home">Home</Link>
          )}
        </div>
        <div className="footer-right">
          <a href="/terms">Terms of Use</a>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;