import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../assets/styles/AboutUs.css';

const AboutUs = () => {
  // Scroll to top when this component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-page">
      <Navbar current="about" />
      <div className="about-us-container">
        <div className="about-us-content">
          <h1>About Us</h1>
          <p>Welcome to Quila LLC, where we believe in making high-quality education accessible to everyone, regardless of location. Our mission is to transform learning by providing personalized, top-tier educational resources to all students.</p>

          <h2>Our Vision</h2>
          <p>Traditional classrooms and large coaching centers often fail to meet the diverse needs of students, especially those in remote areas who lack access to quality education. At Quila LLC, we are committed to changing this by ensuring that every student can learn at their own pace in the way that best suits them. Our goal is simple: no student should be left behind.</p>

          <h2>What We Do</h2>
          <p>We use advanced technology and innovative teaching methods to create a personalized learning environment for every student. Our AI-driven tools adapt to individual strengths and needs, bringing the best teachers and resources directly to students, wherever they are.</p>

          <h2>Why Choose Us?</h2>
          <ul>
            <li><strong>Personalized Learning:</strong> Our platform is tailored to meet each student’s needs, allowing them to learn at their own pace and ensuring they fully understand each concept.</li>
            <li><strong>Focused Attention:</strong> Our approach emphasizes individual attention, helping students overcome challenges and reach their full potential.</li>
            <li><strong>High-Quality Resources:</strong> We provide top-tier educational content to all students, ensuring equal access to high-quality materials.</li>
            <li><strong>Inclusive Education:</strong> We are dedicated to creating a learning environment where every student, regardless of background or location, can succeed.</li>
          </ul>

          <h2>Our Commitment</h2>
          <p>Quila LLC is more than just an educational platform; we are a movement dedicated to giving every student the opportunity to excel. By focusing on individual strengths, offering personalized guidance, and making education accessible to all, we aim to empower every student to succeed.</p>
          <p>Join us on our mission to transform education and make high-quality learning accessible to everyone, everywhere.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;