import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import '../assets/styles/Navbar.css';
import logo from '../assets/images/Logo.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <a href="/" className="home-link"> {/* Link to home */}
          <img src={logo} alt="Company Logo" className="logo" />
          <span className="company-name">uila</span>
        </a>
      </div>
      <div className="navbar-right">
        <a href="https://wa.me/+18339062082" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp className="whatsapp-icon" /> Whatsapp
        </a>
      </div>
    </nav>
  );
};

export default Navbar;