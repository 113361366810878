import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../assets/styles/AboutUs.css'; // Reusing the AboutUs.css for styling

const Terms = () => {
  // Scroll to top when this component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-page"> {/* Keeping the same class name for consistency */}
      <Navbar current="terms" />
      <div className="about-us-container"> {/* Keeping the same class name for consistency */}
        <div className="about-us-content"> {/* Keeping the same class name for consistency */}
          <h1>Terms of Use</h1>
          <p><strong>Latest update: 17 August 2024</strong></p>
          <p>Welcome to Quila LLC ("Company", "we", "our", "us"). These <strong>Terms of Use</strong> ("Terms") govern your use of our website located at [jee.quila.today] ("Website") and all affiliated services (the "Service") provided via WhatsApp or any other digital platforms ("Platforms").</p>
          <p>By accessing or using our Website and Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you must not use our Website or Services.</p>

          <h2><strong>1. Introduction</strong></h2>
          <h3><strong>1.1 Acceptance of Terms</strong></h3>
          <p>By accessing, browsing, or registering on our Website, or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and any guidelines, rules, or policies referenced herein, including our Privacy Policy. If you are a parent or legal guardian and you provide consent for your child to use the Website, you agree to be bound by these Terms in respect of such child's use of the Website.</p>

          <h3><strong>1.2 Eligibility</strong></h3>
          <p>The Service is not available to: (a) any users previously suspended or removed from the Service by us, or (b) any person under the age of 18 whose registration has not been approved by a parent or legal guardian.</p>

          <h3><strong>1.3 Account Registration</strong></h3>
          <p>To access certain features of the Service, you may be required to create an account. You agree to provide accurate, complete, and updated information. You are responsible for maintaining the confidentiality of your account information and all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.</p>

          <h2><strong>2. Services</strong></h2>
          <h3><strong>2.1 AI Chatbot Interaction</strong></h3>
          <p>Our AI tutor is designed to provide adaptive learning experiences based on user interactions. While we strive to offer accurate and helpful information, the AI tutor is an automated tool, and its content should not be considered professional advice or a substitute for professional educational services.</p>

          <h3><strong>2.2 Problem-Solving Assistance</strong></h3>
          <p>The AI chatbot offers problem-solving assistance across various subjects. Users can input questions or problems, and the AI will provide step-by-step explanations and solutions. However, users are responsible for verifying the accuracy of the information provided.</p>

          <h3><strong>2.3 Personalized Learning</strong></h3>
          <p>Our Service adapts to each user's learning style and pace. This personalization is based on user interactions and performance data collected during the use of the Service.</p>

          <h3><strong>2.4 Platform Usage</strong></h3>
          <p>By using our Service, you agree not to:</p>
          <ul>
            <li>Use the Service for any illegal or unauthorized purpose.</li>
            <li>Impersonate any person or entity or misrepresent your affiliation with a person or entity.</li>
            <li>Interfere with or disrupt the Service or servers or networks connected to the Service.</li>
          </ul>

          <h2><strong>3. User Conduct and Responsibilities</strong></h2>
          <h3><strong>3.1 Prohibited Activities</strong></h3>
          <p>You agree not to:</p>
          <ul>
            <li>Use the Service to harm, threaten, or harass others.</li>
            <li>Upload, post, or transmit any content that is illegal, harmful, or violates the rights of others.</li>
            <li>Attempt to gain unauthorized access to any portion of the Website or Service.</li>
          </ul>

          <h3><strong>3.2 Content Ownership and Rights</strong></h3>
          <p>You retain ownership of any content you submit through the Service. However, by submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute such content for the purpose of operating and improving our Service.</p>

          <h2><strong>4. Privacy and Data Collection</strong></h2>
          <h3><strong>4.1 Data Collection</strong></h3>
          <p>We collect and process personal information in accordance with our Privacy Policy, which is incorporated into these Terms by reference. By using the Service, you consent to the collection and use of your personal information as described in our Privacy Policy.</p>

          <h3><strong>4.2 Third-Party Services</strong></h3>
          <p>Our Service may include links to third-party websites or services. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.</p>

          <h2><strong>5. Intellectual Property</strong></h2>
          <h3><strong>5.1 Company Content</strong></h3>
          <p>All content available through the Service, including text, graphics, logos, icons, images, audio clips, and software, is the property of Quila LLC or its licensors and is protected by applicable intellectual property laws.</p>

          <h3><strong>5.2 User Content</strong></h3>
          <p>By submitting any content to the Service, you grant Quila LLC a non-exclusive, royalty-free, worldwide license to use, distribute, reproduce, modify, adapt, and publicly display such content in connection with the Service.</p>

          <h2><strong>6. Legal</strong></h2>
          <h3><strong>6.1 Disclaimers and Limitation of Liability</strong></h3>
          <p>The Service is provided "as is" and "as available," without warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted or error-free.</p>
          <p>To the fullest extent permitted by law, Quila LLC and its affiliates will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of the Service.</p>

          <h3><strong>6.2 Indemnification</strong></h3>
          <p>You agree to indemnify and hold harmless Quila LLC, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including without limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.</p>

          <h2><strong>7. Termination and Account Deletion</strong></h2>
          <h3><strong>7.1 Termination by Company</strong></h3>
          <p>We reserve the right to terminate or suspend your access to the Service at any time, with or without notice, for conduct that we believe violates these Terms or is harmful to other users or us.</p>

          <h3><strong>7.2 Account Deletion</strong></h3>
          <p>You may request to delete your account at any time by contacting our support team. Upon deletion, your personal information will be removed from our active databases, though some information may be retained for legal or operational purposes.</p>

          <h2><strong>8. Modifications to the Service</strong></h2>
          <p>We reserve the right to modify, suspend, or discontinue the Service (or any part or content thereof) at any time with or without notice to you, and we will not be liable to you or to any third party for any such modification, suspension, or discontinuance.</p>

          <h2><strong>9. Dispute Resolution</strong></h2>
          <p>Any disputes arising from these Terms or your use of the Service shall first be attempted to be resolved through informal negotiation. If the dispute cannot be resolved informally, both parties agree to submit to binding arbitration in accordance with the rules of the American Arbitration Association.</p>

          <h2><strong>10. Governing Law</strong></h2>
          <p>These Terms are governed by and construed in accordance with the laws of the respective country of the user. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in your country.</p>

<h2><strong>11. Changes to these Terms</strong></h2>
<p>We may update these Terms from time to time. If we make significant changes, we will notify you by posting the revised Terms on our Website with the date of the last revision. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>

<h2><strong>12. Contact Us</strong></h2>
<p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:support@quila.today">support@quila.today</a>.</p>
</div>
</div>
<Footer />
</div>
);
};

export default Terms;