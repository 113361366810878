import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import '../assets/styles/AboutUs.css'; // Reusing the AboutUs.css for styling

const Privacy = () => {
  // Scroll to top when this component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-page"> {/* Keeping the same class name for consistency */}
      <Navbar current="privacy" />
      <div className="about-us-container"> {/* Keeping the same class name for consistency */}
        <div className="about-us-content"> {/* Keeping the same class name for consistency */}
          <h1>Privacy Policy</h1>
          <p><strong>Last Updated: 17 August 2024</strong></p>
          <p>At Quila LLC ("Company", "we", "our", "us"), your privacy and the security of your personal information are paramount. This <strong>Privacy Policy</strong> ("Policy") outlines the practices we employ to collect, use, share, and protect your information when you interact with our website [www.jee.quila.today] ("Website") and affiliated services, including our AI chatbot and other digital platforms (collectively, the "Services").</p>
          <p>By using our Website and Services, you consent to the terms outlined in this Policy. If you do not agree with any part of this Policy, please discontinue use of our Website and Services.</p>

          <h2><strong>1. Information We Collect</strong></h2>
          <h3><strong>1.1 Information You Provide Directly</strong></h3>
          <p>We collect personal data that you voluntarily provide when you:</p>
          <ul>
            <li>Create an account on our Website.</li>
            <li>Reach out to us through forms, emails, or other communication channels.</li>
            <li>Engage with our Services, such as interacting with our AI chatbot, enrolling in courses, or participating in exams.</li>
            <li>Conduct transactions, including purchasing services or accessing premium content.</li>
            <li>Submit feedback, comments, or participate in forums on our Platforms.</li>
          </ul>
          <p>The personal data we may collect includes, but is not limited to:</p>
          <ul>
            <li>Name (first and last)</li>
            <li>Contact information (such as phone number and email address)</li>
            <li>Date of birth</li>
            <li>Transaction details and history</li>
            <li>Content you provide through communications or forum participation</li>
          </ul>

          <h3><strong>1.2 Information We Collect Automatically</strong></h3>
          <p>We may gather certain information automatically when you interact with our Services. This data may include:</p>
          <ul>
            <li>Device information, such as IP address, browser type, and operating system.</li>
            <li>Usage data, including pages visited, time spent on the Website, and interactions with content.</li>
            <li>Data collected through cookies, web beacons, and similar technologies (see Section 6 for more on cookies).</li>
          </ul>

          <h3><strong>1.3 Information from Third Parties</strong></h3>
          <p>We may receive additional information about you from third-party sources, including:</p>
          <ul>
            <li>Public databases or social media platforms when you engage with our content.</li>
            <li>Service providers that assist us in enhancing the personalization of your experience on our Platforms.</li>
          </ul>

          <h2><strong>2. How We Use Your Information</strong></h2>
          <p>We utilize the information we collect to:</p>
          <ul>
            <li>Facilitate the creation, management, and verification of your account.</li>
            <li>Deliver and enhance our educational Services, including offering personalized learning experiences.</li>
            <li>Communicate with you about updates, promotional offers, and respond to inquiries.</li>
            <li>Conduct analytics to understand how our Services are used and improve their functionality.</li>
            <li>Comply with legal obligations, enforce our terms, and protect against misuse of our Services.</li>
          </ul>

          <h2><strong>3. Sharing Your Information</strong></h2>
          <p>We may share your personal information under the following circumstances:</p>
          <ul>
            <li><strong>With Service Providers:</strong> Such as payment processors, cloud storage providers, and analytics companies that support our business operations.</li>
            <li><strong>With Business Partners:</strong> To enable additional features or services that may be integrated into our Platforms.</li>
            <li><strong>For Legal Compliance:</strong> When required by law, regulation, or legal process, or to protect the rights and safety of our users and our company.</li>
            <li><strong>During Business Transactions:</strong> In connection with any merger, sale of assets, financing, or acquisition of our business by another entity, your data may be transferred.</li>
          </ul>

          <h2><strong>4. Your Rights and Choices</strong></h2>
          <h3><strong>4.1 Access and Update</strong></h3>
          <p>You have the right to access and modify your personal information through your account settings. If you require assistance, you can contact us directly.</p>

          <h3><strong>4.2 Withdrawal of Consent</strong></h3>
          <p>If you have provided consent for certain processing activities, you can withdraw that consent at any time. Note that withdrawing consent may limit your access to certain features of our Services.</p>

          <h3><strong>4.3 Grievances and Disputes</strong></h3>
          <p>If you have any concerns or complaints regarding your data or this Policy, please reach out to our designated Data Protection Officer.</p>

          <h2><strong>5. Data Retention and Deletion</strong></h2>
          <p>We retain your personal information only for as long as it is necessary to fulfill the purposes outlined in this Policy or as required by law. If you choose to delete your account, we will remove your personal information, except where retention is required for legal reasons or to prevent fraud.</p>

          <h2><strong>6. Cookies and Tracking Technologies</strong></h2>
          <p>We use cookies and similar technologies to enhance your user experience, including:</p>
          <ul>
            <li>Recognizing your preferences and settings to personalize your interactions.</li>
            <li>Analyzing how our Services are used to improve performance.</li>
            <li>Delivering targeted content and advertisements that may be of interest to you.</li>
          </ul>
          <p>You can control the use of cookies through your browser settings, though disabling them may affect the functionality of our Services.</p>

          <h2><strong>7. Data Security</strong></h2>
          <p>We are committed to protecting your personal information. We employ various security measures, including encryption and regular audits, to safeguard your data. However, please be aware that no method of online transmission or storage is completely secure. In the event of a data breach that may compromise your personal information, we will notify you promptly in accordance with applicable laws.</p>

          <h2><strong>8. International Data Transfers</strong></h2>
          <p>Your personal information may be transferred to and maintained on servers located outside of your country of residence, where data protection laws may differ from those in your jurisdiction. By using our Services, you consent to such transfers.</p>

          <h2><strong>9. Compliance with Data Protection Regulations</strong></h2>
          <p>We comply with applicable data protection regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). Depending on your jurisdiction, you may have additional rights regarding your personal data. For more information about these rights and how to exercise them, please contact us.</p>

          <h2><strong>10. Updates to This Privacy Policy</strong></h2>
          <p>We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated Policy on our Website. We encourage you to review this Policy regularly.</p>

          <h2><strong>11. Contact Us</strong></h2>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <p>Email: <a href="mailto:support@quila.today">support@quila.today</a></p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;