import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ChatInterface from './components/ChatInterface';
import QRSection from './components/QRSection';
import './assets/styles/App.css';
import './assets/styles/CustomStyles.css';
import convenientImg from './assets/images/convenient.png';
import fastImg from './assets/images/fast.png';
import personalizedImg from './assets/images/personalized.png';

function App() {
  // Scroll to top when this component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Navbar />
      <div className="top-section">
        <main>
          <div className="hero">
            <h1 className="hero-title">Join the JEE-revolution</h1>
            <p className="hero-subtitle">Your Personal Tutor, Wherever you go!</p>
          </div>
          <QRSection />
        </main>
      </div>
      <div className="learning-section">
        <h2 className="learning-title">Unlimited Questions</h2>
        <p className="learning-subtitle">On your fingertips</p>
        <ChatInterface className="chat-interface" />

        <h4 className="learning-footer">Anytime, Anywhere!</h4>
        {/* New Features Section */}
        <div className="features-section">
          <div className="feature-item">
            <img src={convenientImg} alt="Convenient" className="feature-icon" />
            <p>On the Go</p>
          </div>
          <div className="feature-item">
            <img src={fastImg} alt="Instant" className="feature-icon" />
            <p>Fast</p>
          </div>
          <div className="feature-item">
            <img src={personalizedImg} alt="Tailored" className="feature-icon" />
            <p>Personalized</p>
          </div>
        </div>
        {/* End of Features Section */}
      </div>
      <Footer />
    </div>
  );
}

export default App;